:root {
  --black-color-one: rgb(44, 44, 44);
  --grey-color: rgb(95, 95, 95);
  --main-color: rgb(71, 71, 77);
  --orange-color: #ff851b;
  --orange-dark-color: #d66f15;
  --light-grey: rgb(238, 238, 238);
  --blue-color-one: #27adff;
  --blue-color-two: #0f8bd8;
  --light-blue: #ccf3ff;
  overflow-x: hidden;
}

body {
  background: radial-gradient(circle, rgba(142, 142, 142, 0.64) 0%, rgba(37, 37, 37, 1) 80%);
}

.image_div {
  min-height: 100%;
  width: 100%;
  position: relative;
}

.background_img {
  width: 100%;
  height: 100%;
  background-size: contain;
}

.main_div {
  width: 100%;
  position: absolute;
  top: 215px;
}

.outer_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container {
  transform: translateX(19px);
  width: 300px;
  height: 300px;
  perspective: 1000px; /* psst...something fun happens when this is a really small number */
  background-color: transparent;
}

.text_container {
  position: absolute;
  text-align: center;
  top: 98px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.text_div {
  font-family: "Cardo";
  font-size: 30px;
  line-height: 35px;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px var(--grey-color);
  margin-left: 5%;
  margin-right: 5%;
}

#dice_span {
  font-family: "Cardo";
  font-size: 34px;
  font-weight: bold;
  color: var(--blue-color-one);
  text-shadow: 1px 1px var(--light-blue);
}

#cube,
.face {
  position: absolute;
  height: 300px;
  width: 300px;
  transform-style: preserve-3d;
  border-radius: 3%;
  background-color: var(--main-color);
  box-shadow: inset 8px 8px 8px var(--main-color), inset -8px -8px 8px var(--main-color);
}

.face_divs {
  background: var(--grey-color);
  text-align: center;
  border-radius: 20%;
  height: 100%;
  width: 100%;
  box-shadow: inset 8px 8px 8px var(--main-color), inset -8px -8px 8px var(--main-color);
}

.face_div_content {
  text-align: center;
  padding-left: 18px;
  padding-right: 18px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.face_div_header {
  padding-top: 20px;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.face_div_paragraph {
  color: #fff;
  text-align: justify;
  text-align-last: justify;
  margin-bottom: 17px;
  line-height: 1.1;
  word-spacing: 4px;
}

.face_div_button {
  vertical-align: middle;
  margin: auto;
  height: 45px;
  line-height: 45px;
  width: 150px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.face_div_link {
  text-decoration: none;
  color: var(--main-color);
}

.face_div_button:hover {
  color: var(--main-color);
  background-color: lightgrey;
  box-shadow: inset 0 0 2px rgb(32, 32, 32);
}

.arrow {
  cursor: pointer;
  color: var(--black-color-one);
}

.dice-icon {
  margin-top: 30px;
  color: #fff;
}
/* The following is used exclusively for the 'Home_mobile.js'-file */
.carousel_container {
  height: 100%;
  width: 100%;
  padding: 10%;
  padding-top: 5%;
  padding-bottom: 2%;
}

.carousel {
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px) saturate(1.2);
  backdrop-filter: blur(10px) saturate(1.2);
  -webkit-box-shadow: 0px 0px 3px 2px lightgrey;
  box-shadow: 0px 0px 3px 2px lightgrey;
}

.paragraphs_mobile {
  line-height: 25px;
  text-align: center;
  text-align-last: center;
  font-size: 16px;
}

.carousel_container .carousel {
  background: none;
  box-shadow: none;
}

.Home_mobile_title {
  padding-top: 20px;
  font-size: 25px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 10px;
}

/* media queries */

@media screen and (min-width: 1600px) {
  .text_container {
    top: 115px;
  }
  .text_div {
    font-size: 40px;
    line-height: 47px;
  }
  #dice_span {
    font-size: 45px;
  }
  .main_div {
    top: 270px;
  }
}

@media screen and (max-width: 1200px) {
  .background_img {
    height: 930px;
  }
}
