* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
.overlay-background {
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: none; just add for development so that the overlay does not show */
}
.overlay-message {
  background-image: linear-gradient(to right, var(--main-color), var(--grey-color));
  position: absolute;
  width: 47%;
  height: 42%;
  padding: 10px;
  z-index: 6;
  border-radius: 15px;
  border: 2px solid var(--black-color-one);
}
.icon-div {
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 10px solid transparent;
  cursor: pointer;
  box-shadow: 0 0 0 1px var(--black-color-one);
}
.text-icon-container {
  text-align: center;
  margin-top: -45px;
}
.overlay-text-h1 {
  color: white;
  font-size: 32px;
  font-weight: bolder;
  padding-top: 20px;
  margin-top: -15px;
}

/* media queries */

@media screen and (max-width: 1000px) {
  .overlay-text-h1 {
    font-size: 28px;
    margin-top: -20px;
  }
}

@media screen and (max-width: 500px) {
  .overlay-text-h1 {
    font-size: 24px;
    margin-top: -20px;
  }
  .overlay-message {
    width: 49%;
    height: 45%;
    margin: 0px;
  }
  .text-icon-container img {
    width: 90px !important;
  }
}
