#Written-with-React-text {
  white-space: nowrap;
}

.footer-div {
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(37, 37, 37, 1);
  min-height: 80px;
  line-height: 40px;
  width: 100%;
  padding: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.React-image {
  height: 40px;
  width: 40px;
}
.React-image-text {
  padding-left: 4px;
  line-height: 20px;
  font-size: 20px;
  color: white;
  display: flex;
}
.Footer-Links {
  font-weight: 600;
  font-size: 12px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}
.Footer-Links:hover {
  text-decoration: underline;
}
.Footer-dummy-div {
  width: 10%;
}
.other_links_no_contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  justify-content: space-between;
}
.CompanyLogo_image_div {
  background-color: black;
  box-shadow: inset 0px 1px 10px 5px rgba(37, 37, 37, 1);
  padding: 5px;
  transform: translateY(-12px);
}
.CompanyLogo_image {
  height: 40px;
  width: auto;
  border-radius: 10px;
  transform: translateY(7px);
}
.other_links_contact_no_logo {
  width: 100%;
  display: flex;
}
#CompanyLogo_image_div_contact {
  position: absolute;
  margin-top: 70px;
}
#footer-div_contact {
  height: 160px;
}

/* media queries */
@media screen and (max-width: 950px) {
  #footer-div_contact {
    flex-direction: column;
    height: 190px;
  }
  #CompanyLogo_image_div_contact {
    margin-top: 100px;
    margin-left: 40px;
  }
  #dummy-div-1 {
    width: 45px;
  }
}

@media screen and (max-width: 600px) {
  #Written-with-React-text {
    font-size: 15px !important;
  }
  .Footer-dummy-div {
    width: 5%;
  }
  #dummy-div-2 {
    width: 15%;
  }
  #footer-div_contact {
    flex-direction: row;
    height: 160px;
  }
  #footer-div_no_contact {
    flex-direction: column;
  }
  #CompanyLogo_image_div_contact {
    margin-top: 60px;
  }
  .other_links_no_contact {
    flex-direction: column;
    margin-left: 45px;
  }
  .CompanyLogo_image_div {
    transform: translate(-5px, 0px);
    max-width: 120px;
  }
  #privacy_policy_no_contact {
    margin-top: 5px;
  }
}
@media screen and (max-width: 430px) {
  #footer-div_contact {
    flex-direction: column;
  }
  #CompanyLogo_image_div_contact {
    margin-top: 90px;
  }
  #footer-div_contact {
    height: 190px;
  }
}
