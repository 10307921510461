nav {
  box-shadow: inset -2px -2px 2px rgb(32, 32, 32); /* maybe a gradient would be cool here */
  height: 80px;
  background: rgba(37, 37, 37, 1);
  white-space: nowrap;
}
.link-div {
  text-align: center;
  float: right;
  margin-left: -80px;
  transform: translateY(2px);
}
.link {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.link-text {
  font-size: 20px;
  list-style-type: none;
  font-family: sans-serif;
  font-weight: lighter;
  letter-spacing: 1.5px;
  color: #fff;
}
#link-contact {
  float: left;
  padding-top: 25px;
}
#link-home {
  margin-right: 50px;
  float: right;
  padding-top: 25px;
  padding-left: 50px;
}
.toggle-div {
  height: 64px;
  float: left;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 5px;
  display: flex;
}
.toggle-image {
  cursor: pointer;
  height: 100%;
  width: auto;
  border-radius: 50%;
  z-index: 4;
  box-shadow: inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.5);
}
#no-toggle-image {
  cursor: auto;
  margin-left: 4px;
}
.toggle-text {
  min-width: 200px;
  height: 100%;
  background-color: #fff;
  padding-right: 10px;
  padding-left: 100px;
  padding-top: 11px;
  text-align: center;
  transform: translate(25px);
  z-index: 1;
}
.toggle-text h3 {
  font-size: 18px;
  line-height: 29px;
}
.toggle-text p {
  font-size: 12px;
  margin-top: -5px;
}
.toggle-circle {
  height: 100%;
  width: 64px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-78px);
  z-index: 0;
}
.overlaying-div {
  position: absolute;
  height: 64px;
  background-color: rgba(37, 37, 37, 1);
  z-index: 2;
}

/* media queries */

@media screen and (max-width: 350px) {
  #link-home {
    margin-right: 8px;
    padding-left: 14px;
  }
}

@media screen and (max-width: 500px) {
  .link-text {
    font-weight: normal;
  }
}
