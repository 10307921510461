.main_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_div {
  margin-top: 200px;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-color: var(--blue-color-one);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
